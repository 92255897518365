ion-item {
    --padding-start: 0;
    --inner-padding-start: 16px;
    ion-label {
        --color: black !important;
        &.toggle {
            --color: black !important;
        }
    }
    ion-input {
      --placeholder-opacity: 1 !important;
      caret-color: #000000;
    }
    &.first {
        border-top: 1px solid #c8c7cc;
    }
}
ion-radio-group ion-item ion-label {
    --color: black !important;
}

ion-item-divider {
    --background: var(--ion-color-tertiary);
    --padding-start: 0;
    --inner-padding-start: 16px;
    height: 37px;
    border-bottom: 1px solid #c8c7cc;
    &.first {
        border-top: 1px solid #c8c7cc;
    }
}

ion-button {
    border: 1px solid var(--ion-color-medium);
}

ion-select {
    max-width: 75%;
}

ion-accordion-group > ion-accordion ion-item {
    --background: #e5e5e5;
    border-bottom: 1px solid #c8c7cc;
}

.wide-popover {
    --width: 70%;
    --max-width: 250px;
}

.wider-popover {
    --width: 95%;
    --max-width: 320px;
}

.error-message {
    text-align: center;
    color: #ff0000;
    font-size: 14px;
    padding: 5px;
}

.invalid {
    z-index: -1;
    background-color: #FFF0F5;
}

h1 {
    margin-top: 5px;
    margin-bottom: 5px;
}