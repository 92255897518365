// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
	--ion-color-primary: #093a74;
	--ion-color-primary-rgb: 9,58,116;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #083366;
	--ion-color-primary-tint: #224e82;

	--ion-color-secondary: #99ccff;
	--ion-color-secondary-rgb: 153,204,255;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #87b4e0;
	--ion-color-secondary-tint: #a3d1ff;

	--ion-color-tertiary: #f5f5f5;
	--ion-color-tertiary-rgb: 245,245,245;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #d8d8d8;
	--ion-color-tertiary-tint: #f6f6f6;

	--ion-color-success: #f8f8f8;
	--ion-color-success-rgb: 248,248,248;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #dadada;
	--ion-color-success-tint: #f9f9f9;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #ff0000;
	--ion-color-danger-rgb: 255,0,0;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #e00000;
	--ion-color-danger-tint: #ff1a1a;

	--ion-color-medium: #b2b2b2;
	--ion-color-medium-rgb: 178,178,178;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #9d9d9d;
	--ion-color-medium-tint: #bababa;

	--ion-color-light: #ffffff;
	--ion-color-light-rgb: 255,255,255;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #e0e0e0;
	--ion-color-light-tint: #ffffff;

}
